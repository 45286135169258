/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'

import { Theme, useMediaQuery, useTheme, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'

import OverlayText from './overlay-text'

const SKELETONS_NUMBER = 5

enum FakeBanks {
  targobank = '34',
  ingDiba = '55',
  commerzbank = '63',
  dkb = '19',
  postbank = '26',
  deutscheBank = '17',
}

const getSkeletonRandomWidth = (): string => {
  const MIN_WIDTH = 60
  const MAX_WIDTH = 100
  const randomWidthValue = Math.random() * (MAX_WIDTH - MIN_WIDTH) + MIN_WIDTH

  return `${randomWidthValue}%`
}

interface FakeOffersProps {
  skeletonsNumber?: number
  isAnimated?: boolean
}

const FakeOffers = ({ skeletonsNumber, isAnimated }: FakeOffersProps) => {
  const {
    root,
    fakeOffer,
    fakeOfferTitle,
    skeleton,
    skeletonsContainer,
    skeletonBlock,
  } = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={root}>
      <div>
        {Object.keys(FakeBanks).map((key) => (
          <div className={fakeOffer} key={key}>
            <div className={fakeOfferTitle}>
              <img
                src={`https://cdn.smava.de/products/builtin/${
                  FakeBanks[key as keyof typeof FakeBanks]
                }.svg`}
                alt={`logo-${key}}`}
              />
            </div>
            <div className={skeletonsContainer}>
              {[
                ...Array(
                  isMobile
                    ? skeletonsNumber || SKELETONS_NUMBER
                    : SKELETONS_NUMBER,
                ),
              ].map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={skeletonBlock} key={`skeletonblock-${index}`}>
                  <Skeleton
                    className={skeleton}
                    width={getSkeletonRandomWidth()}
                    height={34}
                    animation={isAnimated ? 'wave' : false}
                  />
                  <Skeleton
                    className={skeleton}
                    width={getSkeletonRandomWidth()}
                    height={20}
                    animation={isAnimated ? 'wave' : false}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <OverlayText />
    </div>
  )
}

export default memo(FakeOffers)

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  fakeOffer: {
    marginBottom: theme.spacing(2),
    borderRadius: '5px',
    boxShadow: '1px 2px 4px rgb(0 2 6 / 20%)',

    '&:nth-last-child(2)': {
      marginBottom: 0,
    },

    '&:last-child': {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
        margin: 0,
      },
    },
  },
  fakeOfferTitle: {
    width: '100%',
    padding: `10px 20px`,
    background: theme.palette.grey[50],

    '& img': {
      height: '30px',
    },

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      background: 'none',
      padding: `20px`,
    },
  },
  skeleton: {
    borderRadius: '20px/40px',
  },
  skeletonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 100%)',
    width: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',

    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, calc(100%/3))',
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, 20%)',
    },
  },
  skeletonBlock: {
    padding: theme.spacing(2.5),
    zIndex: -1,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(7.5),
    },

    '&:nth-child(4)': {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },

    '&:last-child': {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
        background: theme.palette.grey[50],
      },
    },
  },
}))
