import { memo } from 'react'

import { Skeleton } from '@mui/material'

const LoadingSkeleton = () => (
  <>
    <Skeleton sx={{ bgcolor: 'grey.100' }} height={150} animation='wave' />
    {Array.from({ length: 3 }, (_, index) => (
      <div key={`loading-skeleton-${index}`}>
        <Skeleton
          sx={{ bgcolor: 'grey.100' }}
          width={200}
          height={32}
          animation='wave'
        />
        <Skeleton
          sx={{ bgcolor: 'grey.100', marginBottom: '10px' }}
          height={60}
          animation='wave'
        />
      </div>
    ))}
    <Skeleton sx={{ bgcolor: 'grey.100' }} height={60} animation='wave' />
  </>
)

export default memo(LoadingSkeleton)
